import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';

const TechIcon = ({ techLogo, techName }) => (
  <TechWrapper>
    <Logo>
      <img src={techLogo} alt={techName} loading="lazy" width="100%" />
    </Logo>
    <TechName>{techName}</TechName>
  </TechWrapper>
);

class TechPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Tech Stack" />

        <h1>This Is My Current Tech</h1>

        <p>
          Every project has its specific tech stack. Here is a list of{' '}
          <strong>technologies</strong>, <strong>frameworks</strong> and{' '}
          <strong>services</strong> that I'm <strong>working with</strong> in
          most of the projects.
        </p>

        <div>
          <TechHolder>
            <TechIcon techLogo="/techstack/react.svg" techName="React" />
            <TechIcon techLogo="/techstack/graphql.svg" techName="GraphQL" />
            <TechIcon techLogo="/techstack/nodejs.svg" techName="NodeJS" />
            <TechIcon techLogo="/techstack/docker.svg" techName="Docker" />
            <TechIcon
              techLogo="/techstack/postgresql.svg"
              techName="PostgreSQL"
            />
            <TechIcon
              techLogo="/techstack/materialui.svg"
              techName="Material UI"
            />
            <TechIcon techLogo="/techstack/gatsby.svg" techName="Gatsby" />
            <TechIcon techLogo="/techstack/mongodb.svg" techName="mongoDB" />
            <TechIcon techLogo="/techstack/redis.svg" techName="Redis" />
            <TechIcon techLogo="/techstack/rabbitmq.svg" techName="RabbitMQ" />
            <TechIcon techLogo="/techstack/redux.svg" techName="Redux" />
            <TechIcon techLogo="/techstack/mobx.svg" techName="MobX" />
            <TechIcon techLogo="/techstack/apollo.svg" techName="Apollo" />
            <TechIcon techLogo="/techstack/sass.svg" techName="Sass" />
            <TechIcon techLogo="/techstack/less.svg" techName="Less" />
            <TechIcon
              techLogo="/techstack/bootstrap.svg"
              techName="Bootstrap"
            />
            <TechIcon
              techLogo="/techstack/letsencrypt.svg"
              techName="Let's Encrypt"
            />
            <TechIcon techLogo="/techstack/jest.svg" techName="Jest" />
            <TechIcon techLogo="/techstack/eslint.svg" techName="ESLint" />
            <TechIcon techLogo="/techstack/travis.svg" techName="Travis CI" />
            <TechIcon
              techLogo="/techstack/digitalocean.svg"
              techName="DigitalOcean"
            />
            <TechIcon techLogo="/techstack/google.svg" techName="Google APIs" />
            <TechIcon
              techLogo="/techstack/cloudinary.svg"
              techName="Cloudinary"
            />
            <TechIcon techLogo="/techstack/babel.svg" techName="Babel" />
            <TechIcon techLogo="/techstack/webpack.svg" techName="Webpack" />
            <TechIcon techLogo="/techstack/npm.svg" techName="npm" />
            <TechIcon techLogo="/techstack/yarn.svg" techName="Yarn" />
            <TechIcon techLogo="/techstack/markdown.svg" techName="Markdown" />
            <TechIcon techLogo="/techstack/git.svg" techName="Git" />
            <TechIcon techLogo="/techstack/slack.svg" techName="Slack" />
            <TechIcon techLogo="/techstack/vscode.svg" techName="VS Code" />
          </TechHolder>
        </div>
      </Layout>
    );
  }
}

export default TechPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  text-align: center;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid ${props => props.theme.primary};

  img {
    width: 60px;
    // height: 60px;
  }
`;

const TechName = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  text-align: center;
`;

const TechWrapper = styled.div`
  display: block;
  width: 33%;
  padding: 10px;
  margin-bottom: 50px;

  @media (max-width: 680px) {
    width: 50%;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
`;

const TechHolder = styled.div`
  display: flex;
  direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;
